<template>
  <q-scroll-area :style="`height: ${$q.screen.height - 80}px`">
    <q-list padding>
      <q-input
        v-if="!miniState"
        v-model="filter"
        type="text"
        :placeholder="$t('search') + '...'"
        dark
        outlined
        debounce="300"
        dense
        class="q-mx-md q-mb-sm"
        ><template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <q-table
        flat
        :data="menuList"
        row-key="label"
        class="bg-transparent text-white"
        hide-pagination
        grid
        :filter="filter"
        :pagination="{ rowsPerPage: 0 }"
      >
        <template v-slot:item="props">
          <div class="col-12">
            <q-item
              v-if="props.row.children && props.row.children.length == 0"
              :to="props.row.path"
              clickable
              v-ripple
              :active="$route.path === props.row.path"
              active-class="active"
            >
              <q-item-section side>
                <q-icon :name="props.row.icon" color="grey-4" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-weight-medium">{{ props.row.label }}</q-item-label>
              </q-item-section>
              <q-tooltip
                v-if="miniState"
                content-class="bg-secondary text-white"
                anchor="center end"
                self="center left"
                max-width="256px"
              >
                {{ props.row.label }}
              </q-tooltip>
            </q-item>

            <q-item-label
              header
              class="text-grey-6 text-weight-medium text-body1"
              v-else-if="props.row.type == 'header'"
            >
              {{ props.row.label }}</q-item-label
            >

            <q-expansion-item
              v-else
              group="menugroup"
              exact-active-class="bg-grey-4"
              active-class="active"
              class="drop-text"
            >
              <template v-slot:header>
                <q-item-section side>
                  <q-icon :name="props.row.icon" color="grey-4" />
                </q-item-section>
                <q-item-section class="text-weight-medium text-blue-3">
                  {{ props.row.label }}
                </q-item-section>
                <q-tooltip
                  v-if="miniState"
                  content-class="bg-secondary text-white"
                  anchor="center end"
                  self="center left"
                  max-width="256px"
                >
                  {{ props.row.label }}
                </q-tooltip>
              </template>
              <q-list class="bg-list-submenu">
                <q-item
                  class="text-white"
                  :to="child.path"
                  clickable
                  v-ripple
                  v-for="(child, i) in props.row.children"
                  :key="i"
                  :active="$route.path === props.row.path"
                  active-class="bg-dark text-white"
                >
                  <q-item-section class="q-pl-xl">
                    <q-item-label class="text-weight-medium">{{ $t(child.label) }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
          </div>
        </template>
      </q-table>

      <q-card
        v-if="!miniState"
        class="q-mt-lg q-pa-md q-ma-md bg-transparent text-white"
        style="border: 2px solid rgba(255, 255, 255, 0.3)"
      >
        <div class="text-body1 q-mb-sm text-white">{{ $t('need_help') }}</div>
        <p class="q-mb-sm">
          <span class="text-white">Email:</span> <br />
          <a class="text-primary" href="mailto:suporte@afil.io">suporte@afil.io</a>
        </p>
        <p class="q-mb-sm">
          <span class="text-white">{{ $t('phone') }}</span> <br />
          <a class="text-primary" href="55212244-8850" target="_blank"> (+55) 21 2244-8850</a>
        </p>
        <p>
          <span class="text-white">Whatsapp:</span> <br />
          <a class="text-primary" href="https://api.whatsapp.com/send?phone=5511996935088" target="_blank"
            >(+55) 21 99693-5088</a
          >
        </p>
      </q-card>
    </q-list>
  </q-scroll-area>
</template>

<script>
import menuList from './menuList.json';
export default {
  name: 'MainMenu',
  props: {
    miniState: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      filter: ''
    };
  },
  computed: {
    menuList() {
      let listMenu = [];
      menuList.forEach((element) => {
        if (element.profile) {
          element.profile.forEach((el) => {
            if (this.user && el === this.user.profile) listMenu.push(element);
          });
        }
      });
      return listMenu.map((item) => {
        return {
          ...item,
          label: this.$t(item.label)
        };
      });
    }
  }
};
</script>

<!-- <style lang="scss" >
.bg-list-submenu {
  background: #ffffff22;
}

.drop-text i {
  color: #ffffff;
}

.q-drawer--mini .help-box {
  display: none;
}

.help-box {
  color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  margin: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-size: 12px;

  .title {
    line-height: 0rem;
  }

  p {
    font-size: 12px;

    a {
      color: rgba(255, 255, 255, 0.5);
    }

    .text-custom {
      color: #7fc1fc !important;
    }
  }
}

h5 {
  font-size: 15px !important;
}
/* .q-expansion-item--expanded .q-item {
  background: #e0e0e0 !important;
} */
/* .active div::before {
  content: "";
  position: absolute;
  right: 0;
  top: -48px;
  width: 48px;
  height: 48px;
  background: transparent;
  border-radius: 10px;
  box-shadow: 35px 35px 0 10px white;
  pointer-events: none;
}
.active div::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -48px;
  width: 48px;
  height: 48px;
  background: transparent;
  border-radius: 10px;
  box-shadow: 35px -35px 0 10px transparent;
  pointer-events: none;
}
.active {
  background: #ffffff !important;
  margin-left: 10px;
  border-radius: 10px 0 0 10px;
}
.active:hover {
  background: #ffffff !important;
  border-radius: 10px 0 0 10px;
} */
/* .q-item:hover {
  background: #ffffff !important;
} */
</style> -->
