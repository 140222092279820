<template>
  <q-btn round flat dense v-if="user">
    <q-avatar size="md">
      <img :src="getGravatar(user.email)" alt="" />
    </q-avatar>
    <q-menu transition-show="jump-down" transition-hide="jump-up">
      <q-card flat style="min-width: 248px" :bordered="$q.dark.isActive">
        <q-card-section class="text-center">
          <q-avatar size="96px" font-size="96px" text-color="grey-5">
            <img :src="getGravatar(user.email)" />
          </q-avatar>
          <div class="text-h6">{{ user.name }}</div>
          <q-badge>{{ user.profile }}</q-badge>
          <div class="text-caption">{{ user.email }}</div>
        </q-card-section>
        <q-separator />
        <q-card-section class="q-pa-none">
          <q-list>
            <q-item clickable v-close-popup to="/profile">
              <q-item-section avatar>
                <q-icon name="mdi-card-account-details-outline" />
              </q-item-section>
              <q-item-section>{{ $t('profile') }}</q-item-section>
            </q-item>
            <!-- <q-item clickable v-close-popup>
              <q-item-section avatar>
                <q-icon name="mdi-form-textbox-password" />
              </q-item-section>
              <q-item-section>{{ $t("change_pass") }}</q-item-section>
            </q-item> -->
            <q-item>
              <q-item-section avatar>
                <q-icon name="mdi-translate" />
              </q-item-section>
              <q-item-section>
                <q-select v-model="language" :options="languages" :label="$t('language')" dense borderless>
                  <template v-slot:selected-item="scope">
                    {{ scope.opt.label }}
                  </template>
                </q-select>
              </q-item-section>
            </q-item>

            <q-item clickable v-close-popup @click="setModeDark">
              <q-item-section avatar>
                <q-icon name="mdi-theme-light-dark" />
              </q-item-section>
              <q-item-section v-if="!$q.dark.isActive">{{ $t('dark_mode') }} </q-item-section>
              <q-item-section v-else>{{ $t('light_mode') }}</q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable v-close-popup @click="logOut">
              <q-item-section avatar>
                <q-icon color="negative" name="mdi-power" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-weight-medium">
                  {{ $t('logout') }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-menu>
  </q-btn>
</template>

<script>
import md5 from 'crypto-js/md5';
import AccountMixin from '../../mixins/AccountMixin';

export default {
  name: 'AccountMenu',
  mixins: [AccountMixin],

  methods: {
    getGravatar(emailAddress) {
      let convertEmailAddress = md5(emailAddress);
      return `https://www.gravatar.com/avatar/${convertEmailAddress}`;
    }
  }
};
</script>

<style scoped></style>
