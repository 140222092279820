import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=00ade36a&scoped=true"
import script from "./MainLayout.vue?vue&type=script&lang=js"
export * from "./MainLayout.vue?vue&type=script&lang=js"
import style0 from "./MainLayout.vue?vue&type=style&index=0&id=00ade36a&prod&lang=css"
import style1 from "./MainLayout.vue?vue&type=style&index=1&id=00ade36a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ade36a",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QFooter from 'quasar/src/components/footer/QFooter.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QDrawer,QItem,QItemSection,QImg,QPageContainer,QFooter,QIcon});
