<template>
  <q-layout view="lHh Lpr lFf">
    <q-header bordered :class="!$q.dark.isActive ? 'bg-grey-2 text-primary q-py-xs' : 'bg-dark text-white q-py-xs'">
      <q-toolbar>
        <q-btn dense flat round @click="menuClick()" aria-label="Menu" :icon="mouseOver ? 'mdi-pin-outline' : 'mdi-pin-off-outline'" />
        <account-menu />
      </q-toolbar>
    </q-header>
    <q-drawer
      bordered
      v-model="leftDrawerOpen"
      show-if-above
      :mini="miniState"
      content-class="bg-menu text-white q-pt-xs"
      :width="256"
      :breakpoint="500"
      @mouseover="mouseOver ? (miniState = false) : miniState"
      @mouseleave="mouseOver ? (miniState = true) : miniState"
    >
      <q-item clickable to="/" style="justify-content: center">
        <q-item-section avatar>
          <q-img
            v-if="!miniState"
            :src="require('@/assets/images/logo_color_white.svg')"
            spinner-color="white"
            width="140px"
          />
          <q-img v-else :src="require('@/assets/images/logotipo_color.svg')" spinner-color="white" width="24px" />
        </q-item-section>
      </q-item>
      <main-menu :miniState="miniState" @closeMiniState="miniState = true" />
    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
    <q-footer class="q-py-sm" :class="!$q.dark.isActive ? 'bg-grey-2 text-dark' : 'bg-dark text-white'">
      Copyright {{ currentYear }} © Afilio: Chasing Performance with
      <q-icon name="mdi-cards-heart" color="black" />
    </q-footer>
  </q-layout>
</template>

<script>
import MainMenu from '@/widgets/MainMenu.vue';
import AccountMenu from '../components/account/AccountMenu';

export default {
  name: 'MainLayout',
  components: { MainMenu, AccountMenu },
  data() {
    return {
      leftDrawerOpen: false,
      miniState: true,
      date: null,
      mouseOver: true
    };
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  },

  methods: {
    drawerClick(e) {
      if (this.miniState) {
        this.miniState = false;
        e.stopPropagation();
      }
    },
    menuClick() {
      if (this.miniState) {
        this.miniState = false;
        this.leftDrawerOpen = true;
      } else {
        this.miniState = true;
        this.leftDrawerOpen = true;
      }

      this.mouseOver = !this.mouseOver;
    }
  },
  created() {
    this.data = new Date();
  }
};
</script>

<style>
.bg-menu {
  background: #0c1c49;
  /* background: linear-gradient(142deg, #002e6e 0%, #007DC5 100%); */
}

footer {
  color: #797979;
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  bottom: 0;
  text-align: left !important;
  padding: 19px 30px 20px;
  position: absolute;
  right: 0;
}
</style>

<style scoped>
.row {
  justify-content: space-between;
}
</style>
